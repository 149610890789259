// Footer

.footer
  position: relative
  background-color: lighten($dark, 5%)
  z-index: 1
  +v-sm
    padding-top: 25px
  .f-bottom
    padding-top: 25px
    background: lighten($dark, 4%)
  .partners
    width: 100%
    padding: 0 10%
    display: flex
    flex-flow: row wrap
    justify-content: space-around
    align-items: center
    height: 200px
    background-color: lighten($dark, 5%)
    +v-sm
      height: 100px
      width: 80%
      margin: 0 auto
    &__link
      margin: 0 16px
      img
        width: auto
        height: 40px
        transition: all ease-out .3s
      &:hover
        img
          opacity: 0.75
.menu
  &--footer
    display: flex
    flex-flow: row wrap
    justify-content: center
    align-items: center
    width: 100%
    +v-sm
      flex-flow: column nowrap
      margin: 40px auto 32px
    &__text,
    &__link
      margin: 0 20px 16px
      color: $lite-blue
      font-size: 1em
      transition: all ease-out .3s
      &:hover
        color: $premium
      &.active
        &:hover
          color: $lite-blue
    &__text
      margin-top: 2px
      font-size: 0.975em
      font-weight: 300
      &:hover
        color: $lite-blue
