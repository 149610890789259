// Margins
.ma
  margin-left: auto
  margin-right: auto
.mla
  margin-left: auto
.mra
  margin-right: auto

  // Width
.w-100
  width: 100%
.w-90
  width: 90%
.w-80
  width: 80%
.w-70
  width: 70%
.w-60
  width: 60%
.w-50
  width: 50%
.w-40
  width: 40%
.w-30
  width: 30%
.w-20
  width: 20%
.w-15
  width: 15%
.w-10
  width: 10%
.w-3
  width: 33.3333%
.w-4
  width: 25%

// Space
.s-10,
.s-20,
.s-30,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100
  width: 100%
  clear: both
  display: block

.s-10
  height: 10px
.s-20
  height: 20px
.s-30
  height: 30px
.s-40
  height: 40px
.s-50
  height: 50px
.s-60
  height: 60px
.s-70
  height: 70px
.s-80
  height: 80px
.s-90
  height: 90px
.s-100
  height: 100px

.section
  display: block
  width: 100%
  height: auto
  min-height: 100vh
  margin: 0
  padding: 20vh 0 30vh
  clear: both
  +v-sm
    min-height: auto
    padding: 10vh 10vw 30vh

.aic
  align-items: center

.aifs
  align-items: flex-start

.aife
  align-items: flex-end

.jcc
  justify-content: center

.jcfs
  justify-content: flex-start

.jcfe
  justify-content: flex-end

.jcsa
  justify-content: space-around

.jcsb
  justify-content: space-between

.center
  justify-content: center

.text-left
  margin: 0
  text-align: left

.text-right
  margin: 0
  text-align: right

.rowrap
  flex-flow: row wrap

.pic
  width: 100%
  height: 200px
  object-fit: cover

.white
  color: #fff

.h-100
  height: 100vh

.h-90
  height: 90vh

.h-80
  height: 80vh

.h-70
  height: 70vh

.h-60
  height: 60vh

.h-50
  height: 50vh

.h-40
  height: 40vh

.h-30
  height: 30vh

.h-20
  height: 20vh

.h-10
  height: 10vh

.r-4
  border-radius: 4px
.r-8
  border-radius: 8px
.r-16
  border-radius: 16px
.r-24
  border-radius: 24px
.r-32
  border-radius: 32px
.r-40
  border-radius: 40px

.r-50
  border-radius: 50%

.a-icon
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  &.la
    margin-left: 10px
  &.ra
    margin-right: 10px
  &.mini
    min-width: 32px !important
    height: 32px !important
  &.small
    min-width: 48px !important
    height: 48px !important
  &.middle
    min-width: 64px !important
    height: 64px !important
  &.large
    min-width: 80px !important
    height: 80px !important
