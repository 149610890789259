.btn
  display: inline-flex
  justify-content: center
  align-items: center
  width: auto
  height: 40px
  padding: 2px 24px 0
  color: $white
  font-size: .85em
  font-weight: 700
  letter-spacing: .05em
  text-transform: uppercase
  border-radius: 24px
  transition: all ease-out .3s
  background: linear-gradient(25.23deg, #4699dd 0%, #3ac7ce 62%, #65bdf7 121.28%)
  // box-shadow: 0 1px 4px #a9299c, 0 2px 1px #fff
  +v-sm
    zoom: .85
  &:hover
    background-color: $dark
  &--premium
    color: $white
    background-color: $premium
  &--big
    padding: 0 48px
    height: 60px
    font-size: 1em
    border-radius: 30px
